import React from 'react';

export function DoubleChevronLeft(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g id='Icon/Outline/Double chevron left'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.90215 3.41005C9.44287 3.95678 9.44287 4.84322 8.90215 5.38995L4.34276 10L8.90215 14.6101C9.44287 15.1568 9.44287 16.0432 8.90215 16.5899C8.36142 17.1367 7.48473 17.1367 6.94401 16.5899L1.40554 10.9899C0.864818 10.4432 0.864818 9.55678 1.40554 9.01005L6.94401 3.41005C7.48473 2.86332 8.36142 2.86332 8.90215 3.41005ZM18.5945 3.41005C19.1352 3.95678 19.1352 4.84322 18.5945 5.38995L14.0351 10L18.5945 14.6101C19.1352 15.1568 19.1352 16.0432 18.5945 16.5899C18.0537 17.1367 17.177 17.1367 16.6363 16.5899L11.0979 10.9899C10.5571 10.4432 10.5571 9.55678 11.0979 9.01005L16.6363 3.41005C17.177 2.86332 18.0537 2.86332 18.5945 3.41005Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
