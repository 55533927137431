import React from 'react';
import { ForwardIcon, Text, V2Button, v2Colors } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';

interface ResourceCardProps {
    title: string;
    description: string;
    buttonText: string;
    buttonUrl: string;
    theme: 'light' | 'dark';
}

const classes = {
    container: (theme: 'light' | 'dark') =>
        ({
            backgroundColor: theme === 'light' ? v2Colors.background.lightest : v2Colors.core.innovationDark,
            border: `1px solid ${v2Colors.border.primary}`,
            borderRadius: '8px',
            padding: '3.6rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1.2rem',
        }) as const,
};

export function ResourceCard({ title, description, buttonText, buttonUrl, theme }: ResourceCardProps): JSX.Element {
    return (
        <div css={classes.container(theme)}>
            <Text variant='h4' color={theme === 'light' ? 'activeSecondary' : 'inversePrimary'}>
                {title}
            </Text>
            <RichTextContent content={description} color={theme === 'light' ? 'activeSecondary' : 'inversePrimary'} />
            <V2Button
                variant={theme === 'light' ? 'primary' : 'tertiary'}
                component='a'
                href={buttonUrl}
                target='_blank'
                rightIcon={<ForwardIcon />}
            >
                {buttonText}
            </V2Button>
        </div>
    );
}
