import React, { useRef, useState } from 'react';
import { Arcade } from '@models/section/productTourSection';
import { Dialog } from '@base-ui-components/react/dialog';
import { fontFamily, fontWeights, IconButton, Text, v2Colors } from '@web-for-marketing/react-ui';
import { DoubleChevronLeft } from '@components/IconComponents/DoubleChevronLeft';
import { DoubleChevronRight } from '@components/IconComponents/DoubleChevronRight';
import { PlayStartIcon } from '@components/IconComponents/PlayStartIcon';

interface ProductTourPlaylistProps {
    arcades: Arcade[];
    activeArcadeIndex: number;
    onArcadeChange?: (arcadeIndex: number) => void;
}

const classes = {
    sideMenuContainer: {
        position: 'relative',
        width: '84px',
    },
    sideMenuCollapsed: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1.6rem',
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '8px',
        backgroundColor: v2Colors.background.white,
        boxSizing: 'border-box',
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.10)',
    },
    sideMenu: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '84px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '1.6rem',
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '8px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: v2Colors.background.white,
        boxSizing: 'border-box',
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.10)',
        transition: 'width 0.4s ease-in-out',
        '& *': {
            transition: 'opacity 0.4s ease-in-out',
        },
        '&[data-open]': {
            width: '390px',
            zIndex: 2,
            '& *': {
                transition: 'opacity 0.4s ease-in-out',
                opacity: 1,
            },
        },
        '&[data-starting-style], &[data-ending-style]': {
            width: '84px',
            '& *': {
                transition: 'opacity 0.4s ease-in-out',
                opacity: 0,
            },
        },
    },
    sideMenuToggle: {
        width: 'unset',
        height: 'unset',
        '&:hover': {
            boxShadow: 'none !important',
        },
    },
    sideMenuContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    sideMenuContentItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.6rem',
    },
    arcadeListItem: (isSelected: boolean) => ({
        color: isSelected ? v2Colors.text.activeSecondary : v2Colors.text.activePrimary,
        fontSize: '1.8rem',
        fontWeight: fontWeights.medium,
        fontFamily: fontFamily.roboto,
        lineHeight: '1.5',
        backgroundColor: isSelected ? v2Colors.action.active : v2Colors.transparent,
        border: 0,
        outline: 0,
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 300ms ease, color 300ms ease',
        '&:focus-visible': {
            outline: `2px solid ${v2Colors.border.activePrimary}`,
            outlineOffset: '3px',
        },
        '&:hover': {
            color: v2Colors.text.activeSecondary,
            backgroundColor: v2Colors.action.active,
        },
    }),
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: v2Colors.transparent,
        zIndex: 1,
    },
} as const;

export function ProductTourPlaylist({
    arcades,
    activeArcadeIndex,
    onArcadeChange,
}: ProductTourPlaylistProps): JSX.Element {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuContainerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={menuContainerRef} css={classes.sideMenuContainer}>
            <Dialog.Root open={menuOpen} onOpenChange={setMenuOpen} modal={false}>
                <div css={classes.sideMenuCollapsed}>
                    <div css={[classes.sideMenuContentItem, { paddingTop: '2.0rem' }]}>
                        <Dialog.Trigger
                            render={
                                <IconButton
                                    variant='floating'
                                    size='small'
                                    label='open playlist drawer'
                                    css={classes.sideMenuToggle}
                                >
                                    <DoubleChevronLeft />
                                </IconButton>
                            }
                        />
                    </div>
                </div>
                <Dialog.Portal container={menuContainerRef.current}>
                    <Dialog.Backdrop css={classes.backdrop} />
                    <Dialog.Popup css={classes.sideMenu} aria-labelledby='arcade-playlist-title'>
                        <div css={classes.sideMenuContentItem}>
                            <Text id='arcade-playlist-title' variant='h4'>
                                Product Tours
                            </Text>
                            <Dialog.Close
                                render={
                                    <IconButton
                                        variant='floating'
                                        size='small'
                                        label='close playlist drawer'
                                        css={classes.sideMenuToggle}
                                    >
                                        <DoubleChevronRight />
                                    </IconButton>
                                }
                            />
                        </div>
                        <div css={classes.sideMenuContent}>
                            {arcades.map(({ title }, index) => (
                                <button
                                    key={index}
                                    onClick={() => {
                                        if (onArcadeChange) {
                                            onArcadeChange(index);
                                        }
                                    }}
                                    css={[
                                        classes.sideMenuContentItem,
                                        classes.arcadeListItem(index === activeArcadeIndex),
                                    ]}
                                >
                                    {title}
                                    <PlayStartIcon />
                                </button>
                            ))}
                        </div>
                    </Dialog.Popup>
                </Dialog.Portal>
            </Dialog.Root>
        </div>
    );
}
