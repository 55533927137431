import React from 'react';

export function PlayStartIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g id='Icon/Outline/Play start'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.54309 1.62776C3.87772 1.44269 4.28476 1.45962 4.60354 1.67189L16.5295 9.61306C16.823 9.80852 17 10.1421 17 10.4999C17 10.8578 16.823 11.1914 16.5295 11.3868L4.60354 19.328C4.28476 19.5403 3.87772 19.5572 3.54309 19.3721C3.20846 19.187 3 18.8297 3 18.4411V2.55877C3 2.1702 3.20846 1.81284 3.54309 1.62776ZM5.07407 4.5134V16.4865L14.0646 10.4999L5.07407 4.5134Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
