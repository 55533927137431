import { AccessibleFormHelper } from '@models/accessibleFormHelper';

export const bannerTitle = {
    name: 'Title',
    helperText: 'Edit the large main title text.',
};
export const bannerDescription = {
    name: 'Description',
    helperText: 'Edit the smaller body text.',
};
export const bannerList = {
    name: 'List',
    helperText: 'Create and remove list items to make a list.',
};
export const bannerBackgroundImage = {
    name: 'Banner image',
    helperText: 'Open the gallery to change the image shown behind the gradient and all of the content.',
};
export const bannerMobileBackgroundImage = {
    name: 'Mobile Background image',
    helperText: 'Open the gallery to change the mobile image shown behind the gradient and all of the content.',
};
export const bannerBackgroundPosition = {
    name: 'Image position slider',
    helperText:
        'Change the position of the image shown behind all of the content. You may not see horizontal changes if an image is landscape.',
};
export const bannerButtonText = {
    name: 'Button text',
    helperText:
        'Edit the text that goes on the button. Make sure the wording is concise and informs a user of the button\'s purpose.',
};
export const bannerButtonLink = {
    name: 'Button link',
    helperText: 'Edit the link location where the button will lead.',
};
export const bannerButtonType = {
    name: 'Button type',
    helperText: 'Change the button\'s style. Either solid blue or outlined in white.',
};
export const bannerButtonTarget = {
    name: 'Open button link in new tab',
    helperText:
        'Change whether or not the button will open links in a new tab. External links will always open in a new tab',
};
export const bannerShowButton = {
    name: 'Show Button',
    helperText: 'Show or hide the button.',
};
export const bannerButtonsAdd = {
    name: 'New Button',
    helperText: 'Create a new button on the banner.',
};
export const bannerAdd = {
    name: 'New Banner',
    helperText: 'Insert a new banner in the scrolling banners.',
};
export const bannerInfiniteScroll = {
    name: 'Infinite Scroll',
    helperText: 'Toggle infinite repeating scroll of banner',
};
export const bannerAutoplay = {
    name: 'Autoplay banner',
    helperText: 'Toggle automatic scrolling of the banner.',
};
export const bannerAutoplayDelay = {
    name: 'Autoplay Delay (milliseconds)',
    helperText: 'Toggle the rate at which the banner transitions in milliseconds.',
};
export const testimonialAuthorRole = {
    name: 'Testimonial Role Title',
    helperText: 'Edit the testimonial role title.',
};
export const bannerIsAnimated = {
    name: 'Animated',
    helperText: 'Toggle on or off the scrolling animation.',
};
export const bannerGradientEnabled = {
    name: 'Remove Banner Gradient',
    helperText:
        'Change whether or not the banner will have a blue gradient background with white text, or a white background with blue title and grey description text.',
};
export const bannerItalicEnabled = {
    name: 'Enable Italic Font Style',
    helperText: 'Change whether or not the description will have an italic font style.',
};
export const bannerButtonsText = (index?: number): AccessibleFormHelper => {
    return {
        name: `Button ${index ? index + 1 : ''} text`,
        helperText: `Edit the text that goes on the ${
            index ? (index === 0 ? 'first ' : 'second ') : ''
        }button. Make sure the wording is concise and informs a user of the button's purpose.`,
    };
};
export const bannerButtonsLink = (index?: number): AccessibleFormHelper => {
    return {
        name: `Button ${index ? index + 1 : ''} link`,
        helperText: `Edit the link location where the  ${
            index ? (index === 0 ? 'first ' : 'second ') : ''
        }button will lead.`,
    };
};
export const bannerButtonsType = (index?: number): AccessibleFormHelper => {
    return {
        name: `Button ${index ? index + 1 : ''} type`,
        helperText: `Change the  ${index ? (index === 0 ? 'first ' : 'second ') : ''}button's style.`,
    };
};
export const bannerButtonsTarget = (index?: number): AccessibleFormHelper => {
    return {
        name: `Button ${index ? index + 1 : ''} link in new tab`,
        helperText: `Change whether or not the ${
            index && index < 2 ? (index === 0 ? 'first ' : 'second ') : ''
        }button will open links in a new tab.`,
    };
};
export const bannerShowButtons = (index: number): AccessibleFormHelper => {
    return {
        name: `Show button ${index ? index + 1 : ''}`,
        helperText: `Show or hide the ${index ? (index === 0 ? 'first ' : 'second ') : ''}button.`,
    };
};
export const bannerParallax = {
    name: 'Parallax ',
    helperText: 'Change whether or not the image will scroll with the page or have a parallax effect.',
};
export const columnCheckBoxText = {
    name: 'Checkbox text ',
    helperText: 'Edit the text that appears next to the checkbox that enables the button.',
};
export const enableConfirmationCheckBox = {
    name: 'Enable confirmation checkbox',
    helperText: 'Toggle to show or hide the confirmation checkbox features.',
};
export const bannerCustomCssEnabled = {
    name: 'Custom CSS Gradient',
    helperText:
        'Enter the css code for a "linear gradient" to be applied to the banner. Ex. linear-gradient(-90deg, rgba(1, 23, 41, 0) 0%, rgb(1, 23, 41) 100%).',
};
export const columnTitle = {
    name: 'Title',
    helperText: 'Edit the main large blue title text above the main content.',
};
export const columnSubtitle = {
    name: 'Subtitle',
    helperText: 'Edit the small grey text above the title.',
};
export const columnName = {
    name: 'Name',
    helperText: 'Edit the name text, either include first name or full name.',
};
export const columnBody = {
    name: 'Body',
    helperText: 'Edit the grey body text.',
};
export const columnHtml = {
    name: 'Html content',
    helperText: 'Use caution when entering HTML. This section will render any HTML code.',
};
export const columnMedia = {
    name: 'Media embed link',
    helperText: 'Enter an embed link to any form of media, if it is youtube be sure to use the embed link.',
};
export const columnLink = {
    name: 'Link',
    helperText: 'Edit the link location where the blue bold link text will lead.',
};
export const columnLinkText = {
    name: 'Link text',
    helperText:
        'Edit the clickable bolded blue link text shown underneath the body text. Make sure the wording is concise and informs a user of the button\'s purpose.',
};
export const columnLinkTarget = {
    name: 'Open Link in new tab',
    helperText: 'Change whether or not the above link will open in a new tab.',
};
export const columnSideType = {
    name: 'Content type',
    helperText: 'Change the type of content that is shown, changes the layout and what content is used.',
};
export const columnSideSwap = {
    name: 'Swap',
    helperText: 'Swap the content from one of the sides to the other.',
};
export const columnSideSpace = {
    name: 'Section spacing',
    helperText: 'The space above and below this section.',
};
export const columnMobileSwap = {
    name: 'Swap mobile stacking order',
    helperText: 'Reverses the order the modules stack on mobile.',
};

export const image = {
    name: 'Image',
    helperText: 'Opens the gallery to change the main image.',
};
export const mobileImage = {
    name: 'Mobile image',
    helperText: 'Opens the gallery to change the mobile image.',
};
export const alignment = {
    name: 'Content alignment',
    helperText: 'Change the direction in which the content aligns and flows.',
};
export const featuresAlignment = {
    name: 'Features alignment',
    helperText: 'Change the direction in which the features are aligned',
};
export const titleAlign = {
    name: 'Title alignment',
    helperText: 'Change the alignment of the title.',
};
export const textAlign = {
    name: 'Text alignment',
    helperText: 'Change the alignment of the text.',
};
export const sectionAnimations = {
    name: 'Animations',
    helperText: 'Enables customizable animations on the section information, which are triggered automatically.',
};
export const sectionBorder = {
    name: 'Section Border',
    helperText: 'Creates a thin border above or below the section.',
};
export const columnShowForm = {
    name: 'Show Form',
    helperText: 'Show or hide the submission form, and button.',
};
export const columnFormInfo = {
    name: 'Form Description',
    helperText: 'Change the small description and notices beneath the form and button.',
};
export const columnFormText = {
    name: 'Form Text',
    helperText: 'Change the text that will go both inside and above the input form.',
};
export const columnFormSetting = {
    name: 'Form Setting',
    helperText:
        'Change the settings used by selected forms. Form key include: html-form-id, marketing-cloud-form-id, form-title, submit-button-text.',
};
export const columnRating = {
    name: 'Rating',
    helperText: 'Add a rating in star form (out of 5).',
};
export const columnButton = {
    name: 'Enable button',
    helperText: 'Toggle to show or hide the button.',
};
export const columnPortrait = {
    name: 'Portrait picture',
    helperText: 'Add a new picture in portrait form.',
};
export const formSelection = {
    name: 'Form Selection',
    helperText: 'Select which form should be displayed',
};
export const formLeadSource = {
    name: 'Lead Source',
    helperText:
        'Change a hidden field that sends information to salesforce, indicating the type/page/campaign this form is used. ',
};
export const formInquirytype = {
    name: 'Inquiry Type',
    helperText: 'Changes a hidden value that gets passed in a hidden text field, which will send to salesforce.',
};

export const formVideoSource = {
    name: 'Video Source',
    helperText: 'Choose to add a video or html embedding in the two column section',
};
export const formButtonText = {
    name: 'Button Text',
    helperText: 'Changes the text of the button in the two column section',
};
export const formButtonLink = {
    name: 'Button Link',
    helperText: 'Edit the link location where the button will lead.',
};
export const formButtonTarget = {
    name: 'Button Target',
    helperText:
        'Change whether or not the button will open links in a new tab. External links will always open in a new tab',
};
export const formReturnUrl = {
    name: 'Return URL',
    helperText: 'The page will be redirected to this url after a successful form submission.',
};
export const formLanguageCategory = {
    name: 'Language Category',
    helperText: 'This is the pre-defined field from salesforce based on current language selection. ',
};
export const formHeaderTitle = {
    name: 'Form Header Title',
    helperText: 'This is the blue title text that is shown on top of the form.',
};
export const sectionStatus = (status: boolean): AccessibleFormHelper => {
    return {
        name: status ? 'Section online' : 'Section offline',
        helperText: 'Will make the section visible to the public, or hidden/offline.',
    };
};
export const columnMultiSubtitle = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} title`,
        helperText: `Edit the small title on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiTitle = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} title`,
        helperText: `Edit the small title on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiBody = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} body text`,
        helperText: `Edit the smaller body text on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiImage = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} image`,
        helperText: `Open the gallery to change the main image on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiTitleLink = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} title link`,
        helperText: `Edit the smaller title link on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiMedia = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} media`,
        helperText: `Open the gallery to change the main media file on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiLink = (index: number, objectType: string, color?: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);
    if (color) {
        return {
            name: `${capitalObjectType} ${index + 1} link`,
            helperText: `Edit the link location where the ${color} link text will link to, on ${objectType} number ${
                index + 1
            }.`,
        };
    } else {
        return {
            name: `${capitalObjectType} ${index + 1} link`,
            helperText: `Edit the link location where the blue bold link text will lead on ${objectType} number ${
                index + 1
            }.`,
        };
    }
};
export const columnMultiLinkText = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} link text`,
        helperText: `Edit the clickable bolded blue link text on ${objectType} number ${
            index + 1
        }. Make sure the wording is concise and informs a user of the button's purpose.`,
    };
};
export const columnMultiLinkTarget = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} link in new tab`,
        helperText: `Change whether or not the link will open in a new tab for the text on ${objectType} number ${
            index + 1
        }.`,
    };
};
export const columnMultiLinkTitleHover = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} hover title`,
        helperText: `Edit the hover title on ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiName = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} name`,
        helperText: `Edit the name that appears for ${objectType} number ${index + 1}.`,
    };
};
export const columnMultiJobTitle = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} job title`,
        helperText: `Edit the grey text describing ${objectType} number ${index + 1}'s job title.`,
    };
};
export const columnMultiDescription = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} biography`,
        helperText: `Edit the body text describing ${objectType} number ${index + 1} as an individual.`,
    };
};
export const columnMultiTwitter = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} Twitter link`,
        helperText: `Edit the link to ${objectType} number ${index + 1}'s Twitter page.`,
    };
};
export const columnMultiLinkedIn = (index: number, objectType: string): AccessibleFormHelper => {
    const capitalObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);

    return {
        name: `${capitalObjectType} ${index + 1} LinkedIn link`,
        helperText: `Edit the link to ${objectType} number ${index + 1}'s LinkedIn page.`,
    };
};
export const columnMultiButtonLink = (index: number): AccessibleFormHelper => {
    return {
        name: `Button ${index + 1} type`,
        helperText: `Change the button / link type of link ${index + 1}.`,
    };
};
export const columnAddHoverCardBox = {
    name: 'Add a hover card box',
    helperText: 'Add a new hover card box to the collection of hover cards.',
};
export const customizableGalleryStackType = {
    name: 'Stack type',
    helperText: 'Change the type of customizable multi-layout used.',
};
export const threeColumnStackType = {
    name: 'Stack type',
    helperText: 'Change the type of three column card layout used.',
};
export const columnAddThreeColumnCard = {
    name: 'Add a card',
    helperText: 'Add a new card to the collection of cards.',
};
export const columnAddProfile = {
    name: 'Add a profile',
    helperText: 'Add a new profile to the collection of profiles.',
};
export const columnAddFeature = {
    name: 'Add a feature',
    helperText: 'Add a new feature group to the collection of features.',
};
export const columnAddLink = {
    name: 'Add a link',
    helperText: 'Add a new link to the collection of links.',
};
export const columnAddRow = {
    name: 'Add a row',
    helperText: 'Add a new row to the collection of rows.',
};
export const titleWidth = {
    name: 'Title Full Width',
    helperText: 'Make title stretch full width of the screen.',
};
export const imageBoxType = {
    name: 'Four column type',
    helperText: 'Change the type of four column content that is displayed.',
};
export const imageZoom = {
    name: 'Image Zoom',
    helperText: 'Enables ability to click on the image and view full size image in popup modal.',
};
export const galleryNewImage = {
    name: 'New image',
    helperText: 'Add a new image to the collection of images in the gallery.',
};
export const gallerySize = {
    name: 'Images\' size',
    helperText: 'Change the size of the images/cards.',
};
export const galleryAlign = {
    name: 'Images\' alignment',
    helperText: 'Change the alignment of the images or cards.',
};
export const galleryLinkEnabled = {
    name: 'Image has link',
    helperText: 'Change whether or not images will link to other pages.',
};
export const customSectionType = {
    name: 'Custom section type',
    helperText: 'Select the component that will be used within this section.',
};
export const videoSearch = {
    name: 'Video search',
    helperText: 'Type a video title to search for it and add it below.',
};
export const featuresType = {
    name: 'Feature type',
    helperText: 'Change the type of of feature layout used.',
};
export const openBottom = {
    name: 'Bottom spacing',
    helperText: 'Change the space on the bottom and whether or not there is a border on the last event.',
};
export const pagePreview = {
    name: 'Public preview',
    helperText: 'Creates a locked un-indexed public preview page.',
};
export const pagePreviewPassword = {
    name: 'Preview password* ',
    helperText: 'Enter the password that will be used for the preview page.',
};
export const eventDates = {
    name: 'Event Dates',
    helperText: 'Type the dates that the event will take place. Follow a "Month Day-Day, Year" format.',
};
export const ariaLabel = {
    name: 'Aria label',
    helperText:
        'An optional informative description of where the link goes. Should be used if the link text itself is not descriptive enough (ex. Read more -> Read more about ...). Avoid saying "click to", or "a link to".',
};
export const eventLocation = {
    name: 'Event Location',
    helperText: 'Type the location that the event will take place. Follow a "Venue, City, Country" format.',
};
export const greyBackgroundEnabled = {
    name: 'Enable Grey Background',
    helperText: 'Change whether or not images/cards will have a white or grey background.',
};
export const referenceId = {
    name: 'Reference Anchor ID',
    helperText: 'This id is used as an anchor for anchor links to jump to',
};
export const buttonsAdd = {
    name: 'Add a new button',
    helperText: 'Add an additional button to the section.',
};
export const buttonsClassName = {
    name: 'Button Class Name',
    helperText: 'Edit the class name of the button.',
};
export const imageUrl = {
    name: 'Image url',
    helperText: '',
};
export const imageAlt = {
    name: 'Image alt',
    helperText:
        'Edit the alternative text for the image, describing the image in detail. Image alt should be more than 15 characters long.',
};
export const mediaUrl = {
    name: 'Media Url',
    helperText: 'Enter an embed URL to a video or html.',
};
export const mediaHeight = {
    name: 'Fixed height',
    helperText: 'Enter a fixed height for the media in pixels. Useful for page embeddings to avoid scrollbars.',
};
export const mediaTitle = {
    name: 'Media title',
    helperText: 'Enter a short but descriptive title of what it is you are embedding.',
};
export const iframeUrl = {
    name: 'Url',
    helperText:
        'The url specified will be embedded into the page. Site security limits the url location of urls. Contact admin to authorize urls.',
};
export const iframeWidth = (isMobile: boolean): AccessibleFormHelper => {
    return {
        name: `${isMobile ? ' Mobile w' : 'W'}idth`,
        helperText: `Set a fixed ${isMobile ? 'mobile ' : ''}width for the iframe. Default is 80%.`,
    };
};
export const iframeHeight = (isMobile: boolean): AccessibleFormHelper => {
    return {
        name: `${isMobile ? ' Mobile h' : 'H'}eight`,
        helperText: `Set a fixed ${isMobile ? 'mobile ' : ''}height for the iframe. Default is 200px.`,
    };
};
export const dateText = {
    name: 'Date',
    helperText: 'Select a date from the date picker popup',
};
export const timeText = {
    name: 'Time',
    helperText: 'Select a time from the time picker popup',
};
export const countdownDateTimeText = {
    name: 'Countdown Date and Time',
    helperText: 'Select a date and a time from the time picker popup',
};
export const iframeBorder = {
    name: 'Iframe Border',
    helperText: 'Toggle the border that frames the iframe container.',
};
export const iframeScale = (isMobile: boolean): AccessibleFormHelper => ({
    name: `Scale ${isMobile ? 'mobile' : ''} iframe`,
    helperText: `Adjust the ${isMobile ? 'mobile width' : 'width'} and ${
        isMobile ? 'mobile height' : 'height'
    } by keeping the ratio the same.`,
});
export const accordionAddList = {
    name: 'Add accordion',
    helperText: 'Create a new collapsing accordion with a header and description',
};
export const accordionType = (type: string): AccessibleFormHelper => ({
    name: `Accordion ${type} type`,
    helperText: `This "${type} type" should explain what the ${type} is. Example: Question, Answer, Description, Patent, Explanation, etc...`,
});
export const accordionCategoryFilter = {
    name: 'Disable category filtering',
    helperText: 'Toggle this to disable the categories above the accordions.',
};
export const accordionCategory = {
    name: 'Category',
    helperText:
        'Write a category to enable grouping and filtering. Any same categories will be grouped and can be viewed if categories are enabled.',
};
export const accordionStructuredDataEnable = {
    name: 'Enable structured data',
    helperText: 'Tick to enable structured data for crawlers.',
};
export const accordionTitle = {
    name: 'Title',
    helperText:
        'Edit the title for the accordion list. This will replace the category title if category filtering is enabled.',
};
export const accordionAllFilter = {
    name: 'All filter',
    helperText:
        'Edit the all category filter for the accordion list. This will replace the category all overview filter label if category filtering is enabled.',
};
export const accordionLoadMore = {
    name: 'Load more',
    helperText:
        'Edit the load more button text for the mobile accordion list. This will replace the button label if category filtering is enabled.',
};
export const accordionHeading = {
    name: 'Heading',
    helperText: 'Edit the main heading text for this collapsable accordion element.',
};
export const accordionDescription = {
    name: 'Description',
    helperText: 'Edit the inner description body text for this collapsable accordion element.',
};
export const duplicatePage = {
    name: 'Duplicate page',
    helperText: 'View the options for creating a copy of the current page.',
};
export const duplicate = (type: string): AccessibleFormHelper => {
    return {
        name: 'Duplicate',
        helperText: `Press the Duplicate button to create a copy of this ${type}.`,
    };
};
export const adminTitle = {
    name: 'Page title',
    helperText: 'Enter a unique and descriptive title for the page. It should be between 20-70 characters.',
};
export const adminDescription = {
    name: 'Description',
    helperText: 'Enter an informative description of the contents of the page. It should be less than 160 characters.',
};
export const adminSlug = {
    name: 'Url slug',
    helperText: 'Enter a unique and valid URL slug; it should be short and concise and not use spaces.',
};
export const adminLanguage = {
    name: 'Language',
    helperText: 'Select a language for the page locale to be set in (and grouped with similar pages).',
};
export const adminRedirect = {
    name: 'Redirect Url',
    helperText: 'Enter a unique URL to redirect to when the current page is reached.',
};
export const adminCanonical = {
    name: 'Canonical Url',
    helperText: 'Enter a unique URL which is the most representative URL for a set of similar pages.',
};
export const adminNavbar = {
    name: 'Navbar',
    helperText: 'Select a custom navbar to be displayed on the page.',
};
export const adminNavbarDropdownTitle = {
    name: 'Dropdown Title',
    helperText: 'Edit the title that will be displayed on the navbar and open the dropdown.',
};
export const adminNavbarButtonStyle = {
    name: 'Button style',
    helperText: 'Select the button\'s style and design.',
};
export const adminNavbarCustomSettingName = {
    name: 'Setting name',
    helperText: 'Affect one of the above available settings to change something about the section.',
};
export const adminNavbarCustomSettingValue = {
    name: 'Value',
    helperText: 'Edit the value for the setting specified to the left.',
};
export const adminNavbarEnableSearch = {
    name: 'Enable Search',
    helperText: 'Enabling this shows the search icon and search bar.',
};
export const adminNavbarEnableLanguageRegion = {
    name: 'Enable language/region link',
    helperText: 'Enabling this will show the link to change languages/regions.',
};
export const adminNavbarEnableMicroNavigation = {
    name: 'Enable Micro navigation visibility',
    helperText: 'Enabling this will show top blue navigation',
};
export const adminNavbarShowLoginButton = {
    name: 'Enable Login Button',
    helperText: 'Enabling this will show login button on micro navigation',
};
export const adminNavbarAddLinkText = {
    name: 'Link Text',
    helperText: 'Edit the text for the link.',
};
export const adminNavbarUrlLink = {
    name: 'Url link or Section id',
    helperText: 'Edit the link\'s url or reference an existing "Section Id" (start ids with a #)',
};
export const adminFooter = {
    name: 'Footer',
    helperText: 'Select a custom footer to be displayed on the page.',
};
export const adminFooterLinkTitle = {
    name: 'Footer link title',
    helperText: 'Edit the title for the footer.',
};
export const adminFooterLink = {
    name: 'Footer link',
    helperText: 'Add a url/link for the footer.',
};
export const adminFooterLinkGroupTitle = {
    name: 'Group title',
    helperText: 'Edit the title that will be displayed above a group of footer links.',
};
export const adminFooterLinkGroupColumn = {
    name: 'Column',
    helperText: 'Select which vertical column the link group will be placed in.',
};
export const adminFooterLinkGroups = {
    name: 'Group links',
    helperText: 'Manage the footer links set within the group.',
};
export const adminFooterSocialMediaLinkTitle = {
    name: 'Footer social media link tooltip title',
    helperText:
        'Edit the title attribute for the footer social media link. This is used to provide extra information about the link for accessibility. Ex: "Go to Geotab\'s instagram page"',
};
export const adminFooterSocialMediaLinkUrl = {
    name: 'Footer social media link url/path',
    helperText: 'Edit the social media url/path for the footer social media link.',
};
export const adminFooterCopyrightText = {
    name: 'Copyright text',
    helperText: 'Edit the small copyright text that appears at the bottom of the footer.',
};
export const adminOnlineStatus = {
    name: 'Online',
    helperText: 'Toggle whether or not the page is online to the public.',
};
export const adminIndex = {
    name: 'Indexed',
    helperText: 'Toggle whether or not the page will appear on searches and is indexed on the web.',
};
export const isClientSideRedirect = {
    name: 'Client-Side Redirect',
    helperText: 'Toggle whether or not client-side redirect is used. When toggled on, Google Analytics can be used.',
};
export const showInSiteSearch = {
    name: 'Show In Site Search',
    helperText: 'Toggle whether or not the redirected page will appear on global site searches',
};
export const adminEnglishTitle = {
    name: 'English page title',
    helperText: 'Enter a unique and descriptive English title that helps describe the page.',
};
export const adminDocumentName = {
    name: 'Document name',
    helperText: 'The name of the document. This will be used as the title and heading for this document\'s page.',
};
export const adminGoogleDocUrl = {
    name: 'Google doc url',
    helperText:
        'A url for a public and shareable google document. Ex: https://docs.google.com/document/d/2PACX-1vQi86CV86PoxTTIsPcmtGdkFx/',
};
export const adminGoogleDriveUrl = {
    name: 'Google drive url',
    helperText:
        'A url for a public and shareable google drive file. Ex: https://drive.google.com/file/d/15rTnEtOCqYc0b9y/view?usp=sharing',
};
export const adminYoutubeUrl = {
    name: 'Youtube url',
    helperText: 'A url for a public and shareable youtube video. Ex: https://www.youtube.com/watch?v=xru2PhwT7t0',
};
export const adminVidyardUrl = {
    name: 'Vidyard url',
    helperText: 'A url for a public and shareable vidyard video. ',
};
export const adminVideoTitle = {
    name: 'Video title',
    helperText: 'A unique and descriptive title for the video and page. It should be between 20-70 characters.',
};
export const adminVideoDescription = {
    name: 'Video description',
    helperText: 'An informative description of the contents of the video.',
};
export const enablePopoutCustomSection = {
    name: 'Enable popout custom section',
    helperText: 'Toggle whether or not the button will open a popout with a custom section when it is interacted with.',
};
export const returnURL = {
    name: 'Return URL',
    helperText: 'Enter a "return URL" to redirect to after the form is submitted.',
};
export const entityRedirectUrl = (type: string): AccessibleFormHelper => {
    return {
        name: 'Redirect Url',
        helperText: `Enter a unique URL to redirect to when the current ${type} is reached.`,
    };
};
export const adminNavbarLanguage = (type: string): AccessibleFormHelper => {
    return {
        name: 'Language',
        helperText: `Select the region and language the ${type} will be used in.`,
    };
};
export const adminNavbarMenuItems = (type: string): AccessibleFormHelper => {
    return {
        name: 'Menu Items',
        helperText: `Customize the ${type} with the available items.`,
    };
};
export const adminDropDownMenuItems = {
    name: 'Dropdown Menu Items',
    helperText: 'Customize the dropdown with the available items.',
};
export const articleLanguage = (type: string): AccessibleFormHelper => {
    return {
        name: 'Language',
        helperText: `Select a language for the ${type}. The language will determine which microsite the ${type} belongs to.`,
    };
};
export const articleTitle = (type: string): AccessibleFormHelper => {
    return {
        name: `${type} Title`,
        helperText: `Enter a unique and descriptive title that helps describe the ${type}.`,
    };
};
export const articlePageTitle = (type: string): AccessibleFormHelper => {
    return {
        name: 'Page Title',
        helperText: `Enter the page title associated with the ${type}.`,
    };
};
export const articleEnglishPageTitle = (type: string): AccessibleFormHelper => {
    return {
        name: 'English Page Title',
        helperText: `Enter a unique and descriptive english title that helps describe the ${type}.`,
    };
};
export const articleSummary = (type: string): AccessibleFormHelper => {
    return {
        name: 'Summary',
        helperText: `Enter an informative description about the contents of the ${type}.`,
    };
};
export const slugEntityBreadcrumbDisplayName = (type = 'page'): AccessibleFormHelper => {
    return {
        name: 'Breadcrumb display name',
        helperText: `Enter a name to be displayed in the breadcrumb trail of the ${type}.`,
    };
};
export const articleVideoSelection = (type: string): AccessibleFormHelper => {
    return {
        name: 'Video Selection',
        helperText: `Add up to 2 videos to your ${type} and arrange their order of display using the right side arrows.`,
    };
};
export const articleMetaDescription = (type: string): AccessibleFormHelper => {
    return {
        name: 'Meta Description',
        helperText: `Enter an informative description that summarizes the contents of the ${type} for the benefit of users and search engines.`,
    };
};
export const articleSlug = (type: string): AccessibleFormHelper => {
    return {
        name: 'Url Slug',
        helperText: `Enter a unique and valid URL slug for the ${type}; it should be short and concise and must have no spaces or symbols.`,
    };
};
export const articleCanonical = (type: string): AccessibleFormHelper => {
    return {
        name: 'Canonical Url',
        helperText: `Enter a unique URL which is the most representative URL for a set of similar ${type}(s).`,
    };
};
export const articleCardImage = (type: string): AccessibleFormHelper => {
    return {
        name: 'Card Image',
        helperText: `Select an image for the ${type}'s card on the website.`,
    };
};
export const articleMobileCardImage = (type: string): AccessibleFormHelper => {
    return {
        name: 'Mobile Card Image',
        helperText: `Select an image for the ${type}'s card on the mobile website.`,
    };
};
export const articleCardImageAlt = (type: string): AccessibleFormHelper => {
    return {
        name: 'Card Image Alt',
        helperText: `Enter an alternative text for the card image, describing the ${type}'s image in detail.`,
    };
};
export const articleHeaderImage = (type: string): AccessibleFormHelper => {
    return {
        name: 'Header Image',
        helperText: `Select a banner image for the ${type} on the desktop version of the website.`,
    };
};
export const articleMobileHeaderImage = (type: string): AccessibleFormHelper => {
    return {
        name: 'Mobile Header Image',
        helperText: `Select a banner image for the ${type} on the mobile version of the website.`,
    };
};
export const articleHeaderImageAlt = (type: string): AccessibleFormHelper => {
    return {
        name: 'Header Image Alt',
        helperText: `Enter an alternative text for the header image, describing the ${type}'s image in detail.`,
    };
};
export const articleHideMobileHeaderImageStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'Hide Mobile Header Image',
        helperText: `Toggle to hide the ${type}'s header image in mobile view.`,
    };
};
export const articlePublishedStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'Published',
        helperText: `Toggle to publish the ${type} and make it publicly available.`,
    };
};
export const articleFeaturedStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'Featured',
        helperText: `Toogle to ensure the ${type} appears in the feature section of its homepage.`,
    };
};
export const articleEditorsPickStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'Editor\'s Pick',
        helperText: `Toogle to ensure the ${type} appears in the editor's pick section of its homepage.`,
    };
};
export const articleShowCardStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'Show Card',
        helperText: `Toggle to display the ${type} card on it public homepage.`,
    };
};
export const articleIndexStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'No Index',
        helperText: `Toggle whether or not the ${type} will appear on searches and is indexed on the web.`,
    };
};
export const articleNoFollowStatus = (type: string): AccessibleFormHelper => {
    return {
        name: 'No Follow',
        helperText: `Toggle to allow ${type} get credits when search engines rank this ${type} against other Geotab.com pages and articles.`,
    };
};
export const articleIsClientSideRedirect = (type: string): AccessibleFormHelper => {
    return {
        name: 'Client-Side Redirect',
        helperText: `'Toggle whether or not client-side redirect is used. When toggled on, Google Analytics can be used on the ${type}.`,
    };
};
export const articleOpenInNewTab = (type: string): AccessibleFormHelper => {
    return {
        name: 'New Tab',
        helperText: `Toggle to ensure the ${type} open in a new tab in the browser.`,
    };
};
export const articleDownloadableCopy = (type: string): AccessibleFormHelper => {
    return {
        name: 'Downloadable Copy',
        helperText: `Attach digital copy of ${type}; so users can download it publicly.`,
    };
};
export const articleTag = (type: string): AccessibleFormHelper => {
    return {
        name: 'Tags',
        helperText: `Select a subject matter description of the ${type}.`,
    };
};
export const videoTag = (type: string): AccessibleFormHelper => {
    return {
        name: 'Tags',
        helperText: `Select a subject matter description of the ${type}.`,
    };
};
export const blogCategory = {
    name: 'Category',
    helperText: 'Select an appropriate category for the blog.',
};
export const articleHiddenTag = {
    name: 'Hide tag on articles',
    helperText:
        'A hidden tag cannot be seen by users on articles. The name will still appear when visiting the tag’s page.',
};
export const blogAuthor = {
    name: 'Author',
    helperText: 'Select the author of this blog post.',
};
export const successStoryClientName = {
    name: 'Client Name',
    helperText: 'Enter the name of the client.',
};
export const successStoryIndustry = {
    name: 'Industry',
    helperText: 'Enter the industry where the client operates.',
};
export const successStoryLocation = {
    name: 'Location',
    helperText: 'Enter the location of this business/establishment.',
};
export const successStoryVehicleTypes = {
    name: 'Vehicle Types',
    helperText: 'Enter the type of vehicles used by the client ex. Sedans, EVs, pickups, dump trucks.',
};
export const successStoryFleetSize = {
    name: 'Fleet-Size',
    helperText: 'Enter an approximate size of the clients fleet.',
};
export const successStoryFleetSizeFilter = {
    name: 'Fleet Size Filter',
    helperText: 'Select fleet filter to help categorize the clients business.',
};
export const successStoryFleetRegionFilter = {
    name: 'Fleet Region Filter',
    helperText: 'Select fleet region filter to help categorize the clients business.',
};
export const successStoryFleetIndustryFilter = {
    name: 'Fleet Industry Filter',
    helperText: 'Select fleet industry filter to help categorize the clients business.',
};
export const successStoryClientImage = {
    name: 'Client Image',
    helperText: 'Selects an image for the case study.',
};
export const successStoryClientImageAlt = {
    name: 'Client Image Alt',
    helperText: 'Enter an alternative text for the image, describing the client image in detail.',
};
export const successStoryFleetFocus = {
    name: 'Fleet Focus Items',
    helperText: 'Select a subject matter describing the clients fleet purpose.',
};
export const relativeLocation = {
    name: 'Relative location',
    helperText:
        'Enter a location that is relative to the author, it does not have to be where they currently live, it can be where they have the most experience.',
};
export const blogEntityName = (type: string): AccessibleFormHelper => {
    return {
        name: `${type} Name`,
        helperText: `${
            type === 'Author' ? `Enter the full or preferred name of the ${type}.` : `Enter the name of the ${type}.`
        }`,
    };
};
export const blogEntityTitle = (type: string): AccessibleFormHelper => {
    return {
        name: 'SEO Title',
        helperText: `Enter a unique and descriptive title that helps describe the ${type}.`,
    };
};
export const blogEntityDescription = (type: string): AccessibleFormHelper => {
    return {
        name: 'SEO Description',
        helperText: `Enter an informative description of the contents of the ${type}.`,
    };
};
export const authorShortBiography = {
    name: 'Short Biography',
    helperText: 'Enter a short biography about the author. It should be one sentence in length.',
};
export const authorBiography = {
    name: 'Biography',
    helperText: 'Enter a full length biography about the author. It should be around 40-60 words and in third person.',
};
export const authorPublicationsURL = {
    name: 'Link URL',
    helperText: 'Enter an external link to the author\'s publications.',
};
export const authorPublications = {
    name: 'External links',
    helperText:
        'Add and enter a list of external links of other publications this author has made. Or relevant links pertaining to the author.',
};
export const authorSocial = (social: string): AccessibleFormHelper => {
    return {
        name: `${social}`,
        helperText: `Enter a link to the author's ${social} profile.`,
    };
};
export const authorImage = {
    name: 'Author Image',
    helperText: 'Select an image for the author.',
};
export const authorImageAlt = {
    name: 'Author Image Alt',
    helperText: 'Enter an alternative text for the image, describing the author\'s image in detail.',
};
export const navbarDropDownColumn = {
    name: 'Columns',
    helperText: 'Select the layout of the popout between a one or two columed layout.',
};
export const navbarDropDownColumnContentStyle = {
    name: 'Content Style',
    helperText:
        'Select the layout and content style of the current column. This affects the options that are available. ',
};
export const navbarDropDownColumnTitle = {
    name: 'Title',
    helperText: 'Edit the title in the dropdown section.',
};
export const navbarDropDownColumnSubTitle = (side: string): AccessibleFormHelper => {
    return {
        name: 'Subtitle',
        helperText: `Edit the subtitle for the ${side} side's column in the dropdown.`,
    };
};
export const navbarDropDownColumnBody = {
    name: 'Body',
    helperText: 'Edit the descriptive body text in the dropdown (under the title).',
};
export const navbarDropDownColumnMainImage = {
    name: 'Main Image',
    helperText: 'Edit the image being displayed in the dropdown.',
};
export const navbarDropDownColumnMainMobileImage = {
    name: 'Main Mobile Image',
    helperText: 'Edit the image being displayed in the mobile dropdown.',
};
export const navbarDropDownColumnMainImageAlt = {
    name: 'Main Image Alt',
    helperText: 'Describe the image being displayed in the dropdown.',
};
export const navbarDropDownColumnVerticalDivider = {
    name: 'Show vertical divider',
    helperText: 'Toggling shows or hides the vertical dividing line between the two columns.',
};
export const navbarDropDownColumnDropdownLink = (side: string): AccessibleFormHelper => {
    return {
        name: 'Dropdown links',
        helperText: `Manage the links set within the ${side} side's column.`,
    };
};
export const heading = {
    name: 'Heading',
    helperText: 'Edit the main heading text.',
};
export const testimonialQuote = {
    name: 'Testimonial Quote',
    helperText: 'Edit the testimonial quote.',
};
export const testimonialAuthor = {
    name: 'Testimonial Author',
    helperText: 'Edit the testimonial author.',
};
export const testimonialAuthorImage = {
    name: 'Testimonial Author Image',
    helperText: 'Open the gallery to change the image for the author shown in the testimonial.',
};
export const testimonialAuthorTitle = {
    name: 'Testimonial Author Title',
    helperText: 'Edit the testimonial author title.',
};
export const goToSection = {
    name: 'Navigation Reference ID',
    helperText:
        'When this field has a value it will enable an arrow button which will navigate to the section with the given reference id.',
};
export const requestConsentText = {
    name: 'Request Consent Text',
    helperText: 'Toggle this switch to enable the request consent text box.',
};
export const dropDownButtonsTarget = (index: number): AccessibleFormHelper => {
    return {
        name: `Open link ${index ? index + 1 : ''} in new tab`,
        helperText: `Change whether or not the ${
            index && index < 2 ? (index === 0 ? 'first ' : 'second ') : ''
        }button will open links in a new tab.`,
    };
};
export const testimonialShowBackground = {
    name: 'Show testimonial background',
    helperText: 'Changes the background of the body text when enabled.',
};
export const testimonialDirection = {
    name: 'Testimonial direction',
    helperText: 'Change the testimonial direction.',
};
export const testimonialStyle = {
    name: 'Testimonial style',
    helperText: 'Change the testimonial style.',
};
export const testimonialRole = {
    name: 'Role/title',
    helperText: 'Edit the role/title.',
};
export const formTitle = {
    name: 'Form title',
    helperText: 'Edit the form title.',
};
export const formSubTitle = {
    name: 'Form subtitle',
    helperText: 'Edit the form subtitle.',
};
export const formId = {
    name: 'Form Id',
    helperText: 'Edit SFMC form id.',
};
export const formHTMLFormId = {
    name: 'HTML Form ID',
    helperText: 'Edit the id identifier for the form.',
};
export const formSubmitButtonText = {
    name: 'Submit button text',
    helperText: 'Edit the submit button text.',
};
export const formSubmitButtonVariant = {
    name: 'Button variant',
    helperText: 'Select a button variant.',
};
export const formSubmitButtonFullWidth = {
    name: 'Enable full width button',
    helperText: 'Toggle to make the button full width.',
};
export const formBackgroundImage = {
    name: 'Background Image',
    helperText: 'Select a 16:9 aspect ratio image for the form background.',
};
export const formBackgroundImageEnabled = {
    name: 'Enable Background Image',
    helperText: 'Toggle to enable background images for the form.',
};
export const formFieldLabel = {
    name: 'Field label',
    helperText: 'Edit the label.',
};
export const formFieldId = {
    name: 'Field id',
    helperText: 'Edit the field id.',
};
export const formFieldType = {
    name: 'Field type',
    helperText: 'Select a component type.',
};
export const formFieldPlaceholder = {
    name: 'Field placeholder',
    helperText: 'Edit the component placeholder.',
};
export const formFieldDefaultValue = {
    name: 'Default value',
    helperText: 'Edit the default value. Save for changes to appear on form.',
};
export const formFieldBreakPoint = {
    name: 'Field breakpoint',
    helperText: 'Select a responsive size for the component',
};
export const formFieldSelectToProceed = (option: string): AccessibleFormHelper => {
    return {
        name: `${option} to proceed`,
        helperText: `Toggle to ensure the user selects ${option} before the form can be submitted.`,
    };
};
export const formFieldRequired = {
    name: 'Required',
    helperText: 'Toggle to make the component required.',
};
export const formFieldHidden = {
    name: 'Hidden',
    helperText: 'Toggle to hide the component from the user using CSS (display: none).',
};
export const formFieldNoOfRows = {
    name: 'Number of rows',
    helperText: 'Selects number of rows the field can occupy (Max: 15 rows).',
};
export const formSelectMessage = (option: string): AccessibleFormHelper => {
    return {
        name: `${option} message`,
        helperText: `Edit ${option} message.`,
    };
};
export const formDefaultMessage = (option: string): AccessibleFormHelper => {
    return {
        name: `${option} default value`,
        helperText: `Select ${option} default value (Save for changes to appear on the form).`,
    };
};
export const authorExpertise = {
    name: 'Relevant expertise',
    helperText: 'Enter a list of expertise that this author has that is relevant to their written articles.',
};
export const authorEducation = {
    name: 'Education',
    helperText: 'Enter a list of the author\'s formal eductaion.',
};
export const authorAccomplishments = {
    name: 'Accomplishments',
    helperText:
        'Enter a list of accomplishments that the author has. This can include being featured on public news, awards, or certifications.',
};
export const accordionBlock = (type: string, key: string): AccessibleFormHelper => {
    return {
        name: `${key}` || 'Menu Items',
        helperText: key
            ? `Add / enter a list of external links of ${key} this ${type} has made. Or relevant links pertaining to the ${type}.`
            : 'Customize the menu with the available items.',
    };
};
export const currentRole = {
    name: 'Current role',
    helperText: 'Enter the author\'s current work role at Geotab.',
};
export const dropDownSameKeyValuePair = {
    name: 'Same option name-value pair',
    helperText: 'Toggle to ensure the dropdown has the same value for its name and option value.',
};
export const CustomDropdownOptions = {
    name: 'Convert to custom dropdown',
    helperText:
        'Toggle to convert the predefined dropdown to a custom dropdown. This allows you to add new options to the dropdown.',
};
export const headerType = {
    name: 'Typography type',
    helperText: 'Select a typography style.',
};
export const headerWeight = {
    name: 'Heading weight',
    helperText: 'Select the heading weight.',
};
export const headerAlignment = {
    name: 'Heading alignment',
    helperText: 'Select the heading alignment.',
};
export const headerSpacing = {
    name: 'Heading spacing',
    helperText: 'The space above and below the heading.',
};
export const addFeatureTab = {
    name: 'Add a feature tab',
    helperText: 'Add up to 6 tabs to the feature accordion.',
};
export const featureTabStyle = {
    name: 'Feature tab style',
    helperText: 'Changes the appearance of the feature accordion tabs.',
};
export const addEvent = {
    name: 'Add an event',
    helperText: 'Add a new event group to the collection of events.',
};
export const autoPlay = {
    name: 'Autoplay',
    helperText: 'Starts video automatically',
};
export const mediaSize = {
    name: 'Select desktop media size',
    helperText: 'Select desktop media size of the video',
};
export const mediaSource = {
    name: 'Select media source',
    helperText: 'Select a video from gallery or provide a link of video',
};
export const embeddedMedia = {
    name: 'Embedded media',
    helperText: 'Opens the gallery to change the embedded media.',
};
export const GTMDataLayer = {
    name: 'Send values to confirmation page',
    helperText:
        'Should be used with the DataLayer Configuration Section on a confirmation page. (thank-you/ by default)',
};
export const glossaryKeyInsights = {
    name: 'Key Insights',
    helperText: 'Enter an informative key insights about the contents.',
};
export const BlogFAQQuestion = {
    name: 'Question',
    helperText: 'Enter a frequently asked question that will help users understand this topic better.',
};
export const BlogFAQAnswer = {
    name: 'Answer',
    helperText: 'Provide a detailed answer to the question above.',
};
export const subNavAddLink = {
    name: 'Navigation items',
    helperText: 'Add a new sub navigation link to the collection of links.',
};
export const subNavLink = (index: number): AccessibleFormHelper => {
    return {
        name: `link ${index + 1} url or section id`,
        helperText: `Edit the link location where the blue bold link text will lead on sub navigation link number ${
            index + 1
        }.`,
    };
};
export const subNavLinkText = (index: number): AccessibleFormHelper => {
    return {
        name: `link ${index + 1} text`,
        helperText: `Edit the clickable bolded blue link text on sub navigation link number ${
            index + 1
        }. Make sure the wording is concise and informs a user of the button's purpose.`,
    };
};
export const subNavLinkTarget = (index: number): AccessibleFormHelper => {
    return {
        name: `link ${index + 1} in new tab`,
        helperText: `Change whether or not the link will open in a new tab for the text on sub navigation link number ${
            index + 1
        }.`,
    };
};
export const featureAccordionMediaType = {
    name: 'Media type',
    helperText: 'Change the type of media that is shown.',
};
