import AustraliaFlag from '@images/CountryFlags/Australia.png';
import BrazilFlag from '@images/CountryFlags/Brazil.png';
import CanadaFlag from '@images/CountryFlags/Canada.png';
import FranceFlag from '@images/CountryFlags/France.png';
import GermanyFlag from '@images/CountryFlags/Germany.png';
import GlobeFlag from '@images/CountryFlags/Globe.png';
import IrelandFlag from '@images/CountryFlags/Ireland.png';
import ItalyFlag from '@images/CountryFlags/Italy.png';
import MexicoFlag from '@images/CountryFlags/Mexico.png';
import NetherlandsFlag from '@images/CountryFlags/Netherlands.png';
import PolandFlag from '@images/CountryFlags/Poland.png';
import SingaporeFlag from '@images/CountryFlags/Singapore.png';
import SpainFlag from '@images/CountryFlags/Spain.png';
import SwedenFlag from '@images/CountryFlags/Sweden.png';
import UAEFlag from '@images/CountryFlags/UAE.png';
import UKFlag from '@images/CountryFlags/UK.png';
import CaribbeanFlag from '@images/CountryFlags/Caribbean.png';
import IndonesianFlag from '@images/CountryFlags/Indonesian.png';
import JapanFlag from '@images/CountryFlags/Japan.png';

export const CountryFlags = {
    australia: AustraliaFlag,
    brazil: BrazilFlag,
    canada: CanadaFlag,
    france: FranceFlag,
    germany: GermanyFlag,
    ireland: IrelandFlag,
    italy: ItalyFlag,
    mexico: MexicoFlag,
    netherlands: NetherlandsFlag,
    poland: PolandFlag,
    singapore: SingaporeFlag,
    spain: SpainFlag,
    sweden: SwedenFlag,
    uae: UAEFlag,
    uk: UKFlag,
    worldwide: GlobeFlag,
    caribbean: CaribbeanFlag,
    indonesian: IndonesianFlag,
    japan: JapanFlag,
};
