import React, { useState } from 'react';
import { ProductTourSectionData } from '@models/section/productTourSection';
import { Grid2, Stack } from '@mui/material';
import { breakpoints, Container, Heading, LazyLoadContainer, Text, v2Colors } from '@web-for-marketing/react-ui';
import { ProductTourPlaylist } from './ProductTourPlaylist';
import { ResourceCard } from './ResourceCard';
import { NoMobileDisplay } from './NoMobileDislplay';

export interface ProductTourProps {
    sectionData: ProductTourSectionData;
    headerSection?: boolean;
    lazyLoad?: boolean;
}

const classes = {
    sectionContainer: {
        padding: '6rem 0',
        [`@media (max-width: ${breakpoints.md})`]: {
            paddingTop: '3.6rem',
            paddingBottom: '3.6rem',
        },
    },
    subSection: {
        paddingTop: '3.6rem',
        '&:not(:last-child)': {
            paddingBottom: '3.6rem',
        },
        width: '100%',
    },
    arcadeWraper: {
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%',
    },
    arcade: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '8px',
    },
    desktopArcadeDisplay: {
        [`@media (max-width: ${breakpoints.md})`]: {
            display: 'none',
        },
    },
    mobileArcadeDisplay: {
        [`@media (min-width: ${breakpoints.md})`]: {
            display: 'none',
        },
    },
    fullWidth: {
        width: '100%',
    },
} as const;

export function ProductTour({ sectionData, headerSection, lazyLoad }: ProductTourProps): JSX.Element {
    const [selectedArcadeIndex, setSelectedArcadeIndex] = useState(0);
    const { arcades, title, resourceCards } = sectionData;

    return (
        <Container css={classes.sectionContainer}>
            <Stack spacing={2}>
                <Text variant='h5' align='center'>
                    Product Tour
                </Text>
                <Heading variant={headerSection ? 'h1' : 'h2'} align='center'>
                    {title}
                </Heading>
                <NoMobileDisplay lazyLoad={lazyLoad} css={classes.mobileArcadeDisplay} />
                <Stack direction='row' spacing={2} css={[classes.desktopArcadeDisplay, classes.subSection]}>
                    <LazyLoadContainer
                        key={`${arcades[selectedArcadeIndex].iframeUrl}-${selectedArcadeIndex}`}
                        lazyload={lazyLoad}
                        css={classes.arcadeWraper}
                    >
                        <iframe
                            title={arcades[selectedArcadeIndex].title}
                            data-src={lazyLoad ? arcades[selectedArcadeIndex].iframeUrl : undefined}
                            src={!lazyLoad ? arcades[selectedArcadeIndex].iframeUrl : undefined}
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            css={classes.arcade}
                        />
                    </LazyLoadContainer>
                    {arcades.length > 1 ? (
                        <ProductTourPlaylist
                            arcades={arcades}
                            activeArcadeIndex={selectedArcadeIndex}
                            onArcadeChange={setSelectedArcadeIndex}
                        />
                    ) : null}
                </Stack>
                <Stack css={classes.subSection} spacing={3}>
                    <Text variant='h2' css={classes.fullWidth}>
                        Explore More Resources
                    </Text>
                    <Grid2 container spacing={3}>
                        {resourceCards.map((resourceCard, index) => (
                            <Grid2 size={{ xs: 12, md: 4 }} key={index}>
                                <ResourceCard {...resourceCard} />
                            </Grid2>
                        ))}
                    </Grid2>
                </Stack>
            </Stack>
        </Container>
    );
}
