import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoMobileAllowed from '@images/no-mobile-allowed.svg';
import { Stack } from '@mui/material';
import { BackIcon, breakpoints, LazyLoadContainer, Text, V2Button, v2Colors } from '@web-for-marketing/react-ui';

interface NoMobileDisplayProps {
    className?: string;
    lazyLoad?: boolean;
}

const classes = {
    messageContainer: {
        padding: '6.4rem 4.6rem',
        backgroundColor: v2Colors.background.white,
        border: `1px solid ${v2Colors.border.primary}`,
        borderRadius: '8px',
    },
    backButton: {
        [`@media (max-width: ${breakpoints.sm})`]: {
            width: '100%',
        },
    },
};

export function NoMobileDisplay({ lazyLoad, className }: NoMobileDisplayProps): JSX.Element {
    const navigate = useNavigate();
    return (
        <Stack spacing={2} alignItems='center' className={className}>
            <Stack direction='column' alignItems='center' spacing={2} css={classes.messageContainer}>
                <Text variant='h2' align='center'>
                    Desktop Required
                </Text>
                <LazyLoadContainer lazyload={lazyLoad}>
                    <img
                        data-src={lazyLoad ? NoMobileAllowed : undefined}
                        src={!lazyLoad ? NoMobileAllowed : undefined}
                        alt='A phone in a circle with a diagonal line through it'
                    />
                </LazyLoadContainer>
                <Text weight='bold' align='center'>
                    Oops! This experience can only be enjoyed on a larger screen.
                </Text>
            </Stack>
            <V2Button variant='secondary' onClick={() => navigate(-1)} leftIcon={<BackIcon />} css={classes.backButton}>
                Go back
            </V2Button>
        </Stack>
    );
}
