import React from 'react';

export function DoubleChevronRight(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g id='Icon/Outline/Double chevron right'>
                <path
                    id='Icon (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.0979 17.0899C10.5571 16.5432 10.5571 15.6568 11.0979 15.11L15.6572 10.5L11.0979 5.88995C10.5571 5.34322 10.5571 4.45678 11.0979 3.91005C11.6386 3.36332 12.5153 3.36332 13.056 3.91005L18.5945 9.51005C19.1352 10.0568 19.1352 10.9432 18.5945 11.4899L13.056 17.0899C12.5153 17.6367 11.6386 17.6367 11.0979 17.0899ZM1.40554 17.0899C0.864817 16.5432 0.864817 15.6568 1.40554 15.11L5.96494 10.5L1.40555 5.88995C0.864818 5.34321 0.864818 4.45678 1.40555 3.91005C1.94627 3.36332 2.82296 3.36332 3.36369 3.91005L8.90215 9.51005C9.44287 10.0568 9.44287 10.9432 8.90215 11.4899L3.36369 17.0899C2.82296 17.6367 1.94627 17.6367 1.40554 17.0899Z'
                    fill='#0078D3'
                />
            </g>
        </svg>
    );
}
