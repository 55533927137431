import loadable from '@loadable/component';
import { LanguageCode } from '@models/languageCode';
import React from 'react';

interface TranslationLoaderProps {
    selectedLanguage: string;
    children: React.ReactNode;
}

export function TranslationLoader({ selectedLanguage, children }: TranslationLoaderProps): JSX.Element | null {
    switch (selectedLanguage) {
        case LanguageCode.en: {
            const ENTranslationsLoadable = loadable(() => import('./ENTranslations'));
            return <ENTranslationsLoadable>{children}</ENTranslationsLoadable>;
        }
        case LanguageCode.enGb: {
            const UKTranslationsLoadable = loadable(() => import('./UKTranslations'));
            return <UKTranslationsLoadable>{children}</UKTranslationsLoadable>;
        }
        case LanguageCode.frCa: {
            const FRCATranslationsLoadable = loadable(() => import('./FRCATranslations'));
            return <FRCATranslationsLoadable>{children}</FRCATranslationsLoadable>;
        }
        case LanguageCode.es: {
            const ESTranslationsLoadable = loadable(() => import('./ESTranslations'));
            return <ESTranslationsLoadable>{children}</ESTranslationsLoadable>;
        }
        case LanguageCode.de: {
            const DETranslationsLoadable = loadable(() => import('./DETranslations'));
            return <DETranslationsLoadable>{children}</DETranslationsLoadable>;
        }
        case LanguageCode.it: {
            const ITTranslationsLoadable = loadable(() => import('./ITTranslations'));
            return <ITTranslationsLoadable>{children}</ITTranslationsLoadable>;
        }
        case LanguageCode.enAu: {
            const AUTranslationsLoadable = loadable(() => import('./AUTranslations'));
            return <AUTranslationsLoadable>{children}</AUTranslationsLoadable>;
        }
        case LanguageCode.frFr: {
            const FRTranslationsLoadable = loadable(() => import('./FRTranslations'));
            return <FRTranslationsLoadable>{children}</FRTranslationsLoadable>;
        }
        case LanguageCode.ptBr: {
            const PTBRTranslationsLoadable = loadable(() => import('./PTBRTranslations'));
            return <PTBRTranslationsLoadable>{children}</PTBRTranslationsLoadable>;
        }
        case LanguageCode.esMx: {
            const ESMXTranslationsLoadable = loadable(() => import('./ESMXTranslations'));
            return <ESMXTranslationsLoadable>{children}</ESMXTranslationsLoadable>;
        }
        case LanguageCode.pl: {
            const PLTranslationsLoadable = loadable(() => import('./PLTranslations'));
            return <PLTranslationsLoadable>{children}</PLTranslationsLoadable>;
        }
        case LanguageCode.nl: {
            const NLTranslationsLoadable = loadable(() => import('./NLTranslations'));
            return <NLTranslationsLoadable>{children}</NLTranslationsLoadable>;
        }
        case LanguageCode.se: {
            const SETranslationsLoadable = loadable(() => import('./SETranslations'));
            return <SETranslationsLoadable>{children}</SETranslationsLoadable>;
        }
        case LanguageCode.ae: {
            const AETranslationsLoadable = loadable(() => import('./AETranslations'));
            return <AETranslationsLoadable>{children}</AETranslationsLoadable>;
        }
        case LanguageCode.ie: {
            const IETranslationsLoadable = loadable(() => import('./IETranslations'));
            return <IETranslationsLoadable>{children}</IETranslationsLoadable>;
        }
        case LanguageCode.apac: {
            const APACTranslationsLoadable = loadable(() => import('./APACTranslations'));
            return <APACTranslationsLoadable>{children}</APACTranslationsLoadable>;
        }
        case LanguageCode.enCb: {
            const ENCBTranslationsLoadable = loadable(() => import('./ENCBTranslations'));
            return <ENCBTranslationsLoadable>{children}</ENCBTranslationsLoadable>;
        }
        case LanguageCode.idn: {
            const IDNTranslationsLoadable = loadable(() => import('./IDNTranslations'));
            return <IDNTranslationsLoadable>{children}</IDNTranslationsLoadable>;
        }
        case LanguageCode.jp: {
            const JPTranslationsLoadable = loadable(() => import('./JPTranslations'));
            return <JPTranslationsLoadable>{children}</JPTranslationsLoadable>;
        }
    }

    return null;
}
