import React from 'react';
import loadable from '@loadable/component';
import { accessLevels, permissions } from '@helpers/authorization';
import { PrivateRoute } from '@components/PrivateRoute';
import { blogEntityType } from '@helpers/blogEntityHelpers';
import { authorModel } from '@helpers/authorForm';
import { categoryModel } from '@helpers/categoryForm';
import { tagModel } from '@helpers/tagForm';
import { Navigate, Route } from 'react-router-dom';
import { ErrorPage } from '@components/ErrorPage';
import { EntityName } from '@models/entity';

const AdminLayoutLoadable = loadable(() => import('./AdminLayout'));
const AllArticlesLoadable = loadable(() => import('@pages/admin/AllArticles'));
const AllDocumentsLoadable = loadable(() => import('@pages/admin/AllDocuments'));
const AllPagesLoadable = loadable(() => import('@pages/admin/AllPages/AllPages'));
const AllEntitiesLoadable = loadable(() => import('@pages/admin/EntityManagement/AllEntities'));
const CreateArticleLoadable = loadable(() => import('@pages/admin/CreateArticle'));
const DuplicateArticleLoadable = loadable(() => import('@pages/admin/DuplicateArticle'));
const CreateBlogEntityLoadable = loadable(() => import('@pages/admin/CreateBlogEntity'));
const CreatePageLoadable = loadable(() => import('@pages/admin/CreatePage'));
const EditEntityLoadable = loadable(() => import('@pages/admin/EntityManagement/EditEntity'));
const AddEntityLoadable = loadable(() => import('@pages/admin/EntityManagement/AddEntity'));
const EditableTemplatePageLoadable = loadable(() => import('@pages/admin/EditableTemplatePage'));
const EditorTemplateLoadable = loadable(() => import('@pages/admin/EditorTemplate'));
const EditArticleSettingsLoadable = loadable(() => import('@pages/admin/EditArticleSettings'));
const HrefLangLoadable = loadable(() => import('@pages/admin/HrefLang/HrefLangOverview'));
const GalleryLoadable = loadable(() => import('@pages/admin/Gallery'));
const NavigationGalleryLoadable = loadable(() => import('@pages/admin/NavigationGallery'));
const NavbarEditingLoadable = loadable(() => import('@components/EditorFormComponents/NavbarEditorForm'));
const NavbarEditingV2Loadable = loadable(() => import('@components/EditorFormComponents/NavbarEditorFormV2'));
const FooterEditingLoadable = loadable(() => import('@components/EditorFormComponents/Footer/FooterEditorForm'));
const ServerLogsLoadable = loadable(() => import('@pages/admin/ServerInfo/ServerLogs'));
const SiteSettingsLoadable = loadable(() => import('@pages/admin/SiteSetting/SiteSetting'));
const OperationsLoadable = loadable(() => import('@pages/admin/Operations/Operations'));
const VideoManagementLoadable = loadable(() => import('@pages/admin/VideoManagement'));
const DuplicationLoadable = loadable(() => import('@pages/admin/Duplicate/DuplicatePage'));
const DashboardLoadable = loadable(() => import('@pages/admin/Dashboard/Dashboard'));
const AllRedirectsLoadable = loadable(() => import('@pages/admin/Redirects/AllRedirects'));
const HistoryLoadable = loadable(() => import('@pages/admin/History'));
const BlogEntityEditorLoadable = loadable(() => import('@pages/admin/BlogEntityEditorTemplate'));
const EditVideoLoadable = loadable(() => import('@pages/admin/EditVideo'));
const EditDocumentLoadable = loadable(() => import('@pages/admin/EditDocument'));
const AddDocumentLoadable = loadable(() => import('@pages/admin/AddDocument'));

export function AdminRouting(): JSX.Element {
    return (
        <Route path='/admin' element={<AdminLayoutLoadable />}>
            <Route
                index
                element={
                    <PrivateRoute permission={permissions.dashboard} minimumAccessLevel={accessLevels.view}>
                        <Navigate to='/admin/dashboard' replace />
                    </PrivateRoute>
                }
            />
            <Route
                path='dashboard'
                element={
                    <PrivateRoute permission={permissions.dashboard} minimumAccessLevel={accessLevels.view}>
                        <DashboardLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='pages/create'
                element={
                    <PrivateRoute permission={permissions.pages} minimumAccessLevel={accessLevels.edit}>
                        <CreatePageLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='pages'
                element={
                    <PrivateRoute permission={permissions.pages} minimumAccessLevel={accessLevels.edit}>
                        <AllPagesLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='pages/redirects'
                element={
                    <PrivateRoute permission={permissions.pages} minimumAccessLevel={accessLevels.edit}>
                        <AllRedirectsLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='pages/videos'
                element={
                    <PrivateRoute permission={permissions.videoGallery} minimumAccessLevel={accessLevels.edit}>
                        <VideoManagementLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='authors'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AllEntitiesLoadable key='authors' entityType={blogEntityType.author} />
                    </PrivateRoute>
                }
            />
            <Route
                path='author/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditEntityLoadable entityType={blogEntityType.author} />
                    </PrivateRoute>
                }
            />
            <Route
                path='author/add'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AddEntityLoadable entityType={blogEntityType.author} model={authorModel} />
                    </PrivateRoute>
                }
            />
            <Route
                path='categories'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AllEntitiesLoadable key='category' entityType={blogEntityType.category} />
                    </PrivateRoute>
                }
            />
            <Route
                path='category/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditEntityLoadable entityType={blogEntityType.category} />
                    </PrivateRoute>
                }
            />
            <Route
                path='category/add'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AddEntityLoadable entityType={blogEntityType.category} model={categoryModel} />
                    </PrivateRoute>
                }
            />
            <Route
                path='tags'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AllEntitiesLoadable key='tag' entityType={blogEntityType.tag} />
                    </PrivateRoute>
                }
            />
            <Route
                path='tag/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditEntityLoadable entityType={blogEntityType.tag} />
                    </PrivateRoute>
                }
            />
            <Route
                path='tag/add'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AddEntityLoadable entityType={blogEntityType.tag} model={tagModel} />
                    </PrivateRoute>
                }
            />
            <Route
                path='uploads/media'
                element={
                    <PrivateRoute permission={permissions.media} minimumAccessLevel={accessLevels.edit}>
                        <GalleryLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='uploads/files'
                element={
                    <PrivateRoute permission={permissions.files} minimumAccessLevel={accessLevels.edit}>
                        <GalleryLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='debug/logs'
                element={
                    <PrivateRoute permission={permissions.serverInfo} minimumAccessLevel={accessLevels.view}>
                        <ServerLogsLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='site-settings'
                element={
                    <PrivateRoute permission={permissions.serverInfo} minimumAccessLevel={accessLevels.view}>
                        <SiteSettingsLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='operations'
                element={
                    <PrivateRoute permission={permissions.serverInfo} minimumAccessLevel={accessLevels.view}>
                        <OperationsLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='tools/navigation-builder/navbar'
                element={
                    <PrivateRoute permission={permissions.navigation} minimumAccessLevel={accessLevels.edit}>
                        <NavigationGalleryLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='tools/navigation-builder/footer'
                element={
                    <PrivateRoute permission={permissions.navigation} minimumAccessLevel={accessLevels.edit}>
                        <NavigationGalleryLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='navbar/edit-form/:id'
                element={
                    <PrivateRoute permission={permissions.navigation} minimumAccessLevel={accessLevels.edit}>
                        <NavbarEditingLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='navbar/v2/edit-form/:id'
                element={
                    <PrivateRoute permission={permissions.navigation} minimumAccessLevel={accessLevels.edit}>
                        <NavbarEditingV2Loadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='footer/edit-form/:id'
                element={
                    <PrivateRoute permission={permissions.navigation} minimumAccessLevel={accessLevels.edit}>
                        <FooterEditingLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='articles/entities'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <CreateBlogEntityLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='articles/create'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <CreateArticleLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='articles'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <AllArticlesLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='blog/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditorTemplateLoadable
                            entityName={EntityName.BlogArticle}
                            settingsPath='/admin/blog/setting/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='blog/duplicate/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <DuplicateArticleLoadable entityName={EntityName.BlogArticle} />
                    </PrivateRoute>
                }
            />
            <Route
                path='blog/setting/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditArticleSettingsLoadable entityName={EntityName.BlogArticle} editPath='/admin/blog/edit/' />
                    </PrivateRoute>
                }
            />
            <Route
                path='press-release/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditorTemplateLoadable
                            entityName={EntityName.PressReleaseArticle}
                            settingsPath='/admin/press-release/setting/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='press-release/duplicate/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <DuplicateArticleLoadable entityName={EntityName.PressReleaseArticle} />
                    </PrivateRoute>
                }
            />
            <Route
                path='press-release/setting/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditArticleSettingsLoadable
                            entityName={EntityName.PressReleaseArticle}
                            editPath='/admin/press-release/edit/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='case-study/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditorTemplateLoadable
                            entityName={EntityName.SuccessStoryArticle}
                            settingsPath='/admin/case-study/setting/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='case-study/duplicate/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <DuplicateArticleLoadable entityName={EntityName.SuccessStoryArticle} />
                    </PrivateRoute>
                }
            />
            <Route
                path='case-study/setting/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditArticleSettingsLoadable
                            entityName={EntityName.SuccessStoryArticle}
                            editPath='/admin/case-study/edit/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='white-paper/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditorTemplateLoadable
                            entityName={EntityName.WhitePaperArticle}
                            settingsPath='/admin/white-paper/setting/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='white-paper/duplicate/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <DuplicateArticleLoadable entityName={EntityName.WhitePaperArticle} />
                    </PrivateRoute>
                }
            />
            <Route
                path='white-paper/setting/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditArticleSettingsLoadable
                            entityName={EntityName.WhitePaperArticle}
                            editPath='/admin/white-paper/edit/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='glossary/edit/:id/'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditorTemplateLoadable
                            entityName={EntityName.GlossaryArticle}
                            settingsPath='/admin/glossary/setting/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='glossary/duplicate/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <DuplicateArticleLoadable entityName={EntityName.GlossaryArticle} />
                    </PrivateRoute>
                }
            />
            <Route
                path='glossary/setting/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <EditArticleSettingsLoadable
                            entityName={EntityName.GlossaryArticle}
                            editPath='/admin/glossary/edit/'
                        />
                    </PrivateRoute>
                }
            />
            <Route
                path='page/:id'
                element={
                    <PrivateRoute permission={permissions.pages} minimumAccessLevel={accessLevels.edit}>
                        <EditableTemplatePageLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='pages/documents'
                element={
                    <PrivateRoute permission={permissions.documents} minimumAccessLevel={accessLevels.edit}>
                        <AllDocumentsLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='tools/hreflang'
                element={
                    <PrivateRoute permission={permissions.hrefLang} minimumAccessLevel={accessLevels.edit}>
                        <HrefLangLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='page/duplicate/:id'
                element={
                    <PrivateRoute permission={permissions.pages} minimumAccessLevel={accessLevels.edit}>
                        <DuplicationLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='history'
                element={
                    <PrivateRoute permission={permissions.dashboard} minimumAccessLevel={accessLevels.view}>
                        <HistoryLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='author/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <BlogEntityEditorLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='category/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <BlogEntityEditorLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='tag/edit/:id'
                element={
                    <PrivateRoute permission={permissions.articles} minimumAccessLevel={accessLevels.edit}>
                        <BlogEntityEditorLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='document/edit/:id'
                element={
                    <PrivateRoute permission={permissions.documents} minimumAccessLevel={accessLevels.edit}>
                        <EditDocumentLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='document/add'
                element={
                    <PrivateRoute permission={permissions.documents} minimumAccessLevel={accessLevels.edit}>
                        <AddDocumentLoadable />
                    </PrivateRoute>
                }
            />
            <Route
                path='video/edit/:id'
                element={
                    <PrivateRoute permission={permissions.videoGallery} minimumAccessLevel={accessLevels.edit}>
                        <EditVideoLoadable />
                    </PrivateRoute>
                }
            />
            <Route path='*' element={<ErrorPage />} />
        </Route>
    );
}
